import { axiosInstance, handleApiErrorOfAxios } from "AxiosInstance";
import { deleteFileEndPoint, fileUploadEndPoint, multiFileUploadEndPoint } from "./endPoint";

export const uploadFileApi = async (data) => {
    const apiFunction = async () => {
        try {
            const response = await axiosInstance.post(fileUploadEndPoint, data);
            return response.data;
        } catch (error) {
            console.log("error", error);
            return error;
        }
    };
    return handleApiErrorOfAxios(apiFunction);
};

export const uploadMultipleFileApi = async (data) => {
    const apiFunction = async () => {
        const response = await axiosInstance.post(multiFileUploadEndPoint, data);
        return response.data;
    };
    return handleApiErrorOfAxios(apiFunction);
};

export const deleteFileApi = async (data) => {
    const apiFunction = async () => {
        const response = await axiosInstance.post(deleteFileEndPoint, data);
        return response.data;
    };
    return handleApiErrorOfAxios(apiFunction);
};
