import moment from "moment";
import { rangeTypes } from "shared/Hooks";

export const loop = (size) => [...Array(size).keys()];

export const kFormatter = (num, fixedLimit = 1) =>
    Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(fixedLimit) + "k" : num;

export const getRandomNumber = (min = 0, max = 1, fixedLimit = 0) => {
    min = fixedLimit ? min : Math.ceil(min);
    max = fixedLimit ? max : Math.floor(max);

    const wrapperFunc = fixedLimit ? (val) => val : Math.floor;
    return +(+wrapperFunc(Math.random() * (max - min + 1)) + min).toFixed(fixedLimit);
};

export const secondsToMinuteFormatter = (seconds = 0) => {
    const s = +(seconds || 0).toFixed(0);
    const min = Math.floor(s / 60).toString();
    const sec = (s % 60).toString().padStart(2, "0");
    return `${numberFormat(min)}m ${sec}s`;
};

export const isFunc = (func) => typeof func === "function";

export const isArray = (arr) => Array.isArray(arr);

export const numberFormat = (num) => new Intl.NumberFormat("en-us").format(num);

export const getSum = (arr) => (isArray(arr) ? arr.reduce((a, b) => a + b, 0) : 0);

export const getAvgValue = (arr) => (isArray(arr) ? getSum(arr) / arr.length : 0);

export const getPercentage = (val, total, fixedLimit = 0) =>
    total === 0 ? 0 : +((val / total) * 100).toFixed(fixedLimit);

export const getDateRangeText = (start, end) => {
    const sd = moment(start);
    const ed = moment(end);

    if (!sd.isValid() || !ed.isValid()) return "";

    const isYearDiff = !ed.isSame(sd, "year");
    const isMonthDiff = !ed.isSame(sd, "month");
    const isDayDiff = !ed.isSame(sd, "day");

    if (isYearDiff) {
        return `${sd.format("MMM D, YYYY")} - ${ed.format("MMM D, YYYY")}`;
    } else if (isMonthDiff) {
        return `${sd.format("MMM D")} - ${ed.format("MMM D, YYYY")}`;
    } else if (isDayDiff) {
        return `${sd.format("MMM D")} - ${ed.format("D, YYYY")}`;
    } else return sd.format("MMM D, YYYY");
};

export const getQueryDefaultOptions = (start, end, selected) => ({
    context: {
        clientName: "onboarding",
    },
    fetchPolicy: "network-only",
    variables: {
        data: {
            // startDate: start || null,
            // endDate: end || null,
            // startDate: start?.toISOString() || null,
            // endDate: end?.toISOString() || null,
            startDate: moment(start).add(5.5, "hour")?.toISOString() || null,
            endDate: moment(end).add(5.5, "hour")?.toISOString() || null,
            // startDate: start?.format("YYYY-MM-DD hh:mm:ss AM") || null,
            // endDate: end?.format("YYYY-MM-DD hh:mm:ss AM") || null,
            filter: selected?.id || "custom",
        },
    },
});
export const getNPSQuestionAnswer = (start, end, selected, nps_id, nps_question_id) => ({
    context: {
        clientName: "onboarding",
    },
    fetchPolicy: "network-only",
    variables: {
        data: {
            // startDate: start?.toISOString() || null,
            // endDate: end?.toISOString() || null,
            startDate: moment(start).add(5.5, "hour")?.toISOString() || null,
            endDate: moment(end).add(5.5, "hour")?.toISOString() || null,
            filter: selected?.id || "custom",
            nps_id: nps_id,
            nps_question_id: nps_question_id,
        },
    },
});

export const arrToObj = (arr, key) =>
    isArray(arr)
        ? arr.reduce(
              (a, b) => ({
                  ...a,
                  [isFunc(key) ? key(b) : b[key]]: b,
              }),
              {},
          )
        : {};

export const filters = {
    HOUR: "hour",
    DAY: "day",
    WEEk: "week",
    MONTH: "month",
    YEAR: "year",
    QUARTER: "quarter",
};

export const filterOptions = {
    [filters.HOUR]: {
        diff: "hours",
        add: "hours",
        format: "hh A",
    },
    [filters.DAY]: {
        diff: "days",
        add: "days",
        format: "YYYY-MM-DD",
    },
    [filters.WEEk]: {
        diff: "week",
        add: "week",
        format: "YYYY-MM-DD",
    },
    [filters.MONTH]: {
        diff: "months",
        add: "months",
        format: "YYYY-MM",
    },
    [filters.YEAR]: {
        diff: "year",
        add: "year",
        format: "YYYY-MM",
    },
    [filters.QUARTER]: {
        diff: "quarter",
        add: "quarter",
        format: "YYYY-MM",
    },
};

const getFilteredData = (arr, dateKey, valueKey, filterBy, start, end) => {
    const { diff: difference, add, format } = filterOptions[filterBy];
    const diff = end.diff(start, difference) + 1;
    const obj = arrToObj(arr, (b) => moment(b[dateKey]).format(format));
    if (diff >= 0)
        return loop(diff).map((key) => {
            const date = moment(start).add(key, add).format(format);
            return {
                [dateKey]: date,
                [valueKey]: obj[date]?.[valueKey] || 0,
            };
        });
    return [];
};

export const getFilterWiseData = (arr, dateKey, valueKey, filterBy = filters.DAY, start, end) => {
    if (!start || !end) return [];
    const func = getFilteredData.bind(null, arr, dateKey, valueKey, filterBy);
    return {
        [filters.MONTH]: func(start, end),
        [filters.DAY]: func(start, end),
        [filters.WEEk]: func(start, end),
        [filters.HOUR]: func(start.startOf("day"), end.endOf("day")),
        [filters.YEAR]: func(start, end),
        [filters.QUARTER]: func(start, end),
    }[filterBy];
};

export const chartDateFormatters = {
    [filters.HOUR]: (start, end) => (start.isSame(end, "day") ? "hh A" : "DD hh A"),

    [filters.DAY]: (start, end) => (start.isSame(end, "month") ? "DD" : "MMM DD"),
    [filters.WEEk]: (start, end) => (start.isSame(end, "day") ? "DD" : "MMM DD"),
    [filters.MONTH]: (start, end) => (start.isSame(end, "year") ? "MMM" : "YYYY MMM"),
    [filters.YEAR]: (start, end) => (start.isSame(end, "year") ? "YYYY" : "YYYY MMM"),
    [filters.QUARTER]: (start, end) => (start.isSame(end, "year") ? "YYYY" : "YYYY MMM"),
};

export const getMinDate = moment.min;

export const getFeatureReportViewsText = (type, start, end) => {
    const text =
        {
            [rangeTypes.TODAY]: "of today",
            [rangeTypes.YESTERDAY]: "of yesterday",
            [rangeTypes.LAST_7_DAYS]: "in last 7 days",
            [rangeTypes.LAST_14_DAYS]: "in last 14 days",
            [rangeTypes.LAST_30_DAYS]: "in last 30 days",
            [rangeTypes.THIS_MONTH]: "in this month",
            [rangeTypes.LAST_MONTH]: "in last month",
            [rangeTypes.ALL_TIME]: "of all time",
        }[type] ?? `in ${getDateRangeText(start, end)}`;

    return `Views ${text}`;
};
