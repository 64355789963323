import { ProductContext } from "App";
import moment from "moment";
import { useContext, useEffect } from "react";

export const rangeTypes = {
    TODAY: "today",
    YESTERDAY: "yesterday",
    LAST_7_DAYS: "last 7 days",
    LAST_14_DAYS: "last 14 days",
    THIS_MONTH: "this month",
    LAST_30_DAYS: "last 30 days",
    LAST_MONTH: "last month",
    ALL_TIME: "all time",
    CUSTOM: "custom",
};

const dateRanges = () => [
    {
        id: rangeTypes.TODAY,
        label: "Today",
        start: moment().startOf("day"),
        end: moment(),
    },
    {
        id: rangeTypes.YESTERDAY,
        label: "Yesterday",
        start: moment().subtract(1, "day").startOf("day"),
        end: moment().subtract(1, "day").endOf("day"),
    },
    {
        id: rangeTypes.LAST_7_DAYS,
        label: "Last 7 days",
        start: moment().subtract(6, "days"),
        end: moment(),
    },
    {
        id: rangeTypes.LAST_14_DAYS,
        label: "Last 14 days",
        start: moment().subtract(13, "days"),
        end: moment(),
    },
    {
        id: rangeTypes.LAST_30_DAYS,
        label: "Last 30 days",
        start: moment().subtract(29, "days"),
        end: moment(),
    },
    {
        id: rangeTypes.THIS_MONTH,
        label: "This Month",
        start: moment().startOf("month"),
        end: moment(),
    },

    {
        id: rangeTypes.LAST_MONTH,
        label: "Last Month",
        start: moment().subtract(1, "month").startOf("month"),
        end: moment().subtract(1, "month").endOf("month"),
    },
    {
        id: rangeTypes.ALL_TIME,
        label: "All Time",
        start: null,
        end: null,
    },
];

const format = "YYYY-MM-DD";

export const getDateRangeData = (s, e) => {
    let data;
    if (!s && !e) {
        data = dateRanges().find((item) => item.id === rangeTypes.ALL_TIME);
    } else {
        data = dateRanges().find(
            ({ start, end }) =>
                start?.format(format) === s.format(format) &&
                end?.format(format) === e.format(format),
        );
    }
    if (data)
        return {
            isCustom: false,
            start: s,
            end: e,
            selected: {
                id: data.id,
                label: data.label,
            },
        };
    else
        return {
            isCustom: true,
            start: moment(s).startOf("day"),
            end: moment(e).endOf("day"),
            selected: null,
        };
};

const useDateRange = () => {
    const { selectedDateRangeLabel } = useContext(ProductContext);
    const { dateValue: value, setDateValue: setValue } = useContext(ProductContext);
    useEffect(() => {
        const dateFilter = dateRanges().find((item) => item.label === selectedDateRangeLabel);
        if (dateFilter) {
            setValue(getDateRangeData(dateFilter.start, dateFilter.end));
        }
    }, []);
    useEffect(() => {
        const dateFilter = dateRanges().find((item) => item.label === selectedDateRangeLabel);
        if (dateFilter) {
            setValue(getDateRangeData(dateFilter.start, dateFilter.end));
        }
    }, [selectedDateRangeLabel]);
    // const [value, setValue] = useState({
    //     ...getDateRangeData(moment().startOf("month"), moment()),
    // });

    const handleRangeChange = ([start, end]) => {
        setValue((prev) => ({
            ...prev,
            ...getDateRangeData(
                moment(new Date(start)),
                end ? moment(new Date(end)).endOf("day") : moment(new Date(start)).endOf("day"),
            ),
        }));
    };

    const handleTypeChange = (newType) => {
        setValue((prev) => ({
            ...prev,
            isCustom: false,
            selected: {
                id: newType.id,
                label: newType.label,
            },
            start: newType.start,
            end: newType.end,
        }));
    };

    const getDateRangePickerProps = () => ({
        value: [value.start, value.end],
        onRangeChange: handleRangeChange,
        onTypeChange: handleTypeChange,
        selected: value.selected,
        dateRanges: dateRanges(),
        isCustom: value.isCustom,
    });

    return {
        getDateRangePickerProps,
        start: value.start,
        end: value.end,
        selected: value.selected,
    };
};

export default useDateRange;
