import React, { useCallback } from "react";
import { Box, Stack } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Styles from "./ImageUpload.module.css";
import fallbackImage from "shared/Assets/Icons/image.svg";
import { forwardRef, useRef, useState } from "react";
import { useEffect } from "react";
import ReactLoading from "react-loading";
import { ErrorMessage } from "..";
import { MAX_IMAGE_SIZE_ALLOWED_IN_BYTES } from "CommonData/config";
import { uploadImageWithValidation } from "shared/utils/aws";

export const allowedImageTypes = ["image/png", "image/jpg", "image/jpeg", "image/svg+xml"];

function UploadButtonLabel({ name = "", uploading = false, buttonLabel = "", inputRef = null }) {
    return (
        <div className={Styles["gs-upload-button"]}>
            <label className={Styles["gs-custom-upload-button"]} htmlFor={name}>
                {uploading ? (
                    <ReactLoading
                        className={Styles["gs-button-loader-bubble"]}
                        type={"bubbles"}
                        color={"var(--primary-color)"}
                    />
                ) : (
                    buttonLabel
                )}
            </label>
            <input
                accept="image/png, image/jpg, image/jpeg, image/svg+xml"
                type={"file"}
                id={name}
                ref={inputRef}
            />
        </div>
    );
}

const Image = forwardRef((props, ref) => <img alt="Upload" ref={ref} {...props} />);

const ExtendedImageUploadWrapper = (props) => {
    const {
        name,
        label,
        description,
        imageRef,
        inputRef,
        uploading = false,
        error = null,
        buttonLabel,
    } = props;

    return (
        <>
            <div className={Styles["gs-title"]}>{label}</div>
            <div className={Styles["gs-image"]}>
                <Box
                    sx={{
                        width: "100%",
                    }}
                >
                    <div className={Styles["gs-image-preview"]}>
                        <Image
                            style={{ display: "flex", justifyContent: "center" }}
                            height={"50px"}
                            width={"100%"}
                            ref={imageRef}
                        />
                    </div>
                    <p
                        className={Styles["gs-image-description"]}
                        variant="caption"
                        color="var(--secondary-light-color)"
                    >
                        {description}
                    </p>
                    <div className={`${Styles["gs-extended-upload-button-wrapper"]}`}>
                        <UploadButtonLabel
                            inputRef={inputRef}
                            buttonLabel={buttonLabel}
                            name={name}
                            uploading={uploading}
                        />
                        {!!error && <ErrorMessage message={error.message} />}
                    </div>
                </Box>
            </div>
        </>
    );
};

const DefaultImageUploadWrapper = (props) => {
    const {
        imageRef,
        name = "",
        uploading = false,
        error = {},
        inputRef = null,
        buttonLabel,
    } = props;
    return (
        <Stack alignItems="flex-start" justifyContent="flex-start">
            <Stack direction="row" rowGap={2} columnGap={2} alignItems="center">
                <Image
                    width={"40px"}
                    height={"auto"}
                    style={{ padding: "2px" }}
                    alt="language"
                    id="imagePreview"
                    ref={imageRef}
                    onError={handleImageError}
                />
                <div className="gs-upload-button-wrapper">
                    <div>
                        <UploadButtonLabel
                            name={name}
                            uploading={uploading}
                            buttonLabel={buttonLabel}
                            inputRef={inputRef}
                        />
                    </div>
                </div>
            </Stack>
            {!!error && <ErrorMessage message={error?.message} />}
        </Stack>
    );
};

function handleImageError(e) {
    e.currentTarget.onerror = null;
    e.target.src = fallbackImage;
}

const components = {
    extended: ExtendedImageUploadWrapper,
    default: DefaultImageUploadWrapper,
};
async function urlToBase64(url) {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data.split(",")[1]);
                return base64data;
            };
            reader.onerror = () => {
                reject(new Error("Error reading the file"));
            };
        });
    } catch (error) {
        console.error("Error fetching the URL:", error);
        throw error;
    }
}

export const ImageUpload = (props) => {
    const {
        name,
        path,
        noSizeValidate,
        acceptTypes,
        label = "Upload",
        variant = "default",
        onChange = null,
        buttonLabel = "Upload",
    } = props;
    /**
     * *GET A COMPONENT BASED ON GIVEN VARIANT.
     * *Default variant is default
     */
    const Component = components[variant];

    const { control, clearErrors, setError, setValue, watch } = useFormContext();
    const selectedImage = watch(name);
    const imageRef = useRef(null);
    const inputRef = useRef(null);
    const [uploading, setUploading] = useState(false);
    const [image, setImage] = useState();
    const isSvg = selectedImage?.includes(".svg");

    const handleImageUpload = useCallback(
        async (e) => {
            try {
                setUploading(true);
                const files = e.target.files;
                const flatArray = Object.values(files);
                if (!allowedImageTypes.includes(files[0]?.type)) {
                    throw new Error("File type not allowed");
                } else if (files[0].size > MAX_IMAGE_SIZE_ALLOWED_IN_BYTES) {
                    throw new Error("File size should be less than 2 MB");
                } else if (flatArray.length) {
                    const results = await Promise.allSettled(
                        flatArray.map(async (file) => {
                            let newImage = {
                                name: file.name,
                                url: URL.createObjectURL(file),
                                file,
                            };
                            const response = await uploadImageWithValidation(
                                file,
                                path,
                                noSizeValidate,
                                acceptTypes,
                                file.type,
                            );

                            if (response.status) {
                                newImage.name = response.originalName;
                                newImage.url = response.data?.Location || "";
                                return newImage;
                            } else {
                                return {
                                    error:
                                        response?.message?.message ||
                                        response?.message ||
                                        "Something went wrong!",
                                };
                            }
                        }),
                    );
                    const successfulImages = results
                        .filter((result) => result.status === "fulfilled")
                        .map((result) => result.value);

                    // Update state only if there's a successful upload
                    if (successfulImages.length > 0) {
                        setImage(successfulImages[0].url); // Assuming only one image is shown
                    }
                    clearErrors(name);
                    onChange && onChange(successfulImages);
                    setValue(name, successfulImages[0].url, { shouldDirty: true });
                }
            } catch (error) {
                setUploading(false);
                setError(name, {
                    type: "custom",
                    message: error?.message ?? "Oops. Something went wrong. Please try again",
                });
            } finally {
                setUploading(false);
            }
        },
        [path, noSizeValidate, acceptTypes, onChange, setValue, name],
    );

    useEffect(() => {
        const currentRef = inputRef.current;
        if (currentRef) {
            currentRef.addEventListener("change", handleImageUpload);
            return () => currentRef.removeEventListener("change", handleImageUpload);
        }
    }, [handleImageUpload]);

    if (isSvg) {
        urlToBase64(selectedImage).then((data) => {
            const test = "data:image/svg+xml;base64," + data;
            setImage(test);
        });
    }
    useEffect(() => {
        if (image?.length) {
            imageRef.current.src = image;
        } else {
            imageRef.current.src = fallbackImage;
        }
    }, [image]);

    useEffect(() => {
        if (selectedImage?.length) {
            imageRef.current.src = selectedImage;
        } else {
            imageRef.current.src = fallbackImage;
        }
    }, [selectedImage]);

    return (
        <Controller
            name={name}
            control={control}
            render={({ fieldState: { error } }) => {
                return (
                    <Component
                        error={error}
                        uploading={uploading}
                        variant={variant}
                        inputRef={inputRef}
                        imageRef={imageRef}
                        image={isSvg ? image : selectedImage}
                        handleImageError={handleImageError}
                        label={label}
                        buttonLabel={buttonLabel}
                        {...props}
                    />
                );
            }}
        />
    );
};
