import React from "react";
import { TextField, AdapterDayjs, LocalizationProvider, MUIDatePicker } from "../";

import styles from "./DatePicker.module.css";

const dayNames = {
    su: "sun",
    mo: "mon",
    tu: "tue",
    we: "wed",
    th: "thu",
    fr: "fri",
    sa: "sat",
};

export const DatePicker = ({
    fullWidth = true,
    label,
    // Required
    value,
    onChange,
    renderInput,
    inputReadOnly,
    ...rest
}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MUIDatePicker
                PopperProps={{
                    className: styles["date-picker"],
                }}
                label={label}
                value={value}
                OpenPickerButtonProps={{ className: styles["date-picker-icon"] }}
                onChange={onChange}
                dayOfWeekFormatter={(day) =>
                    dayNames[day.substring(0, 2).toLowerCase()]?.toUpperCase()
                }
                renderInput={({ className, ...params }) =>
                    renderInput ? (
                        renderInput({ className, ...params })
                    ) : (
                        <TextField
                            {...params}
                            fullWidth={fullWidth}
                            {...(inputReadOnly
                                ? {
                                      onKeyDown: (e) => e.preventDefault(),
                                  }
                                : {})}
                        />
                    )
                }
                componentsProps={{
                    leftArrowButton: {
                        disableRipple: true,
                        className: styles["date-picker-arrow"],
                    },
                    rightArrowButton: {
                        disableRipple: true,
                        className: styles["date-picker-arrow"],
                    },
                }}
                {...rest}
            />
        </LocalizationProvider>
    );
};
