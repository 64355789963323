import axios from "axios";
import { getCookie } from "shared/Assets/JS/Cookies";
import { Observable } from "shared/Assets/JS/Utilities";
import { useCommonFunction } from "shared/Hooks";

export const authObserver = new Observable();
window.authObserver = authObserver;

const token = getCookie("admin_access_token");
const subdomain = getCookie("admin_sub_domain");

export const axiosInstance = axios.create({
    headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        "sub-domain": subdomain,
    },
});

function redirectToLogin(url) {
    const urlObj = new URL(url);

    // Split the hostname into parts
    const hostnameParts = urlObj.hostname.split(".");

    // Remove the subdomain (first part)
    hostnameParts.shift();

    // Update the hostname without the subdomain
    urlObj.hostname = hostnameParts.join(".");

    // Change the path to /login
    urlObj.pathname = "/login";

    return urlObj.href;
}

axiosInstance.interceptors.response.use(
    async (response) => {
        return response;
    },
    async (error) => {
        const { handleRefreshToken } = useCommonFunction();
        const { config, response } = error;

        if (response) {
            if (response?.status === 401) {
                try {
                    await handleRefreshToken();
                    // Retry the original request after refreshing the token
                    return axiosInstance(config);
                } catch (refreshError) {
                    handleApiError(refreshError); // Handle error during token refresh
                    return Promise.reject({ data: null, error: "Token refresh failed" });
                }
            } else {
                // Handle other specific status codes if needed
                handleApiError(error); // Log or handle the error
            }
        } else if (error.request) {
            console.log("No response received:", error.request);
        } else {
            console.log("Axios error:", error.message);
        }

        return Promise.reject(error); // Always return the rejected error for further handling
    },
);

// Function to handle Axios errors
export const handleApiError = (error) => {
    if (error.response) {
        console.error("API Error Response:", error.response.data);
        console.error("Status Code:", error.response.status);
        if (error.response.status === 401) {
            const updatedUrl = redirectToLogin(window.location.href);
            window.location.replace(updatedUrl);
        }
    } else if (error.request) {
        console.error("API Request Error:", error.request);
    } else {
        console.error("API Error:", error.message);
    }
};

// use this function to handle API errors
export const handleApiErrorOfAxios = async (
    apiFunction,
    url,
    method = "get",
    data = {},
    params = {},
) => {
    try {
        const response = await apiFunction(url, method, data, params);
        return response;
    } catch (error) {
        console.log(error);
        // You can handle the error further if needed, e.g., notify or throw a custom error
        return error.response.data;
    }
};
/**
 * Generates a full API URL by combining the base URL and endpoint.
 *
 * @param {string} BASE_URL - The base URL of the API.
 * @param {string} endpoint - The specific API endpoint.
 * @returns {string} The full API URL.
 */
export const generateAPIPrefix = (BASE_URL) => (endpoint) => `${BASE_URL}/${endpoint}`;
