import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styles from "./PhoneNumberInput.module.css";

const PhoneNumberInput = ({
    onlyCountries,
    fieldLabel,
    onChange,
    verifyButtonLabel,
    onClickVerifyButton,
    className,
    disabled,
    value,
    autoFormat = false,
    isValid,
    ...rest
}) => {
    return (
        <div>
            <label htmlFor="phonenumber" className={styles["gs-phone-input-label"]}>
                {fieldLabel}
            </label>
            <div className={styles["gs-country-code-phone-input-wrapper"]}>
                <PhoneInput
                    className={
                        disabled
                            ? `${styles["gs-country-code-phone-input"]} ${
                                  styles["phone-number-disabled"]
                              } ${className || ""}`
                            : `${styles["gs-country-code-phone-input"]}  ${className || ""}`
                    }
                    disabled={disabled}
                    name={"phone"}
                    country={"in"}
                    value={value}
                    onlyCountries={onlyCountries}
                    enableSearch={true}
                    autoFormat={autoFormat}
                    inputProps={{
                        name: "phone",
                        required: true,
                        autoFormat: true,
                    }}
                    inputStyle={{
                        width: "100%",
                        border: "1px solid transparent",
                        height: "42px",
                        color: disabled ? "rgba(0, 0, 0, 0.38)" : "var(--secondary-light-color)",
                        cursor: "auto",
                    }}
                    onChange={onChange}
                    disableSearchIcon={true}
                    isValid={isValid}
                    {...rest}
                />
                <button
                    type="button"
                    className={styles["gs-country-code-phone-input-status"]}
                    onClick={onClickVerifyButton}
                >
                    {verifyButtonLabel}
                </button>
            </div>
        </div>
    );
};

export { PhoneNumberInput };
