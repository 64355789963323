import styles from "./Banners.module.css";
import React from "react";

export const AccessDenied = () => {
    return (
        <div className={styles["gs-access-main"]}>
            <img src="/image/Access.png" alt="Access Denied" width={"300px"} />
            <h2> You don’t have permission!</h2>
            <p className={styles["gs-description-text"]}>
                Roles provide a way for community administrators to group permissions and assign
                them to users or user groups. Permissions define the actions that a user can perform
                in a community
            </p>
        </div>
    );
};
