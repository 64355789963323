import { ProductContext } from "App";
import { useContext } from "react";
import { clearCookie, getCookie, setCookie } from "shared/Assets/JS/Cookies";
import { useQuery } from "@apollo/client";
import axios from "axios";

export const useCommonFunction = () => {
    const { setNotification, permissions, setPermissions, setPermissionLoading } =
        useContext(ProductContext);
    let urlWithSubdomain = `${process.env.REACT_APP_LOGIN_SERVER}`.split("//");
    const AxiosUrl = `${urlWithSubdomain[0]}//${urlWithSubdomain[1]}`;

    //   type: success || error || warning || info

    const notify = (type, message) => {
        setNotification({
            visible: true,
            message: message,
            type: type,
        });
    };

    const CommanFunctionsForRoleAndPermission = (query, variables) => {
        const permissionObj = {};
        const { loading } = useQuery(query, {
            variables: {
                feature_key: variables,
            },
            fetchPolicy: "network-only",
            awaitRefetchQueries: true,
            onCompleted: (item) => {
                setPermissions(item.getFeaturePermissionByFeatureKey.data);
                setPermissionLoading(true);
                setTimeout(() => setPermissionLoading(false), 1500);
            },
        });
        permissions &&
            permissions.map((p) => {
                permissionObj[p.feature_key] = {
                    ...p.application.permission_application[0],
                    ...p.permission_application_feature[0],
                };
            });

        return { loading, permissionObj };
    };

    const handleRefreshToken = async () => {
        const token = getCookie("admin_refresh_token");

        await axios
            .post(
                `${process.env.REACT_APP_LOGIN_SERVER}/user/refresh-token`,
                {
                    grant_type: "refresh_token",
                },
                {
                    headers: {
                        authorization: token ? `Bearer ${token}` : "",
                        "sub-domain": getCookie("admin_sub_domain"),
                    },
                },
            )
            .then(({ data }) => {
                if (data.status) {
                    clearCookie();
                    setCookie("admin_access_token", data?.data?.access_token, 10000);
                    setCookie("admin_refresh_token", data?.data?.refresh_token, 10000);
                    setCookie("admin_sub_domain", data?.data?.sub_domain, 10000);
                    window.location.reload();
                }
            });
    };

    return {
        notify,
        AxiosUrl,
        CommanFunctionsForRoleAndPermission,
        handleRefreshToken,
    };
};

export const hextoHSL = (H) => {
    let r = 0,
        g = 0,
        b = 0;
    if (H.length === 4) {
        r = "0x" + H[1] + H[1];
        g = "0x" + H[2] + H[2];
        b = "0x" + H[3] + H[3];
    } else if (H.length === 7) {
        r = "0x" + H[1] + H[2];
        g = "0x" + H[3] + H[4];
        b = "0x" + H[5] + H[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        // s = 0,
        l = 0;

    if (delta === 0) h = 0;
    else if (cmax === r) h = ((g - b) / delta) % 6;
    else if (cmax === g) h = (b - r) / delta + 2;
    else h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0) h += 360;

    l = (cmax + cmin) / 2;
    // s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    // s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return l;
};

/**
 * Formats a number with "M" for millions and "B" for billions if the number is greater than 1000000.
 * @param {number} num - The number to be formatted.
 * @returns {string} The formatted number with suffix "M" or "B" if applicable, otherwise the original number as string.
 */
export function formatNumber(num) {
    if (!num) return 0;
    if (Math.abs(num) >= 1000) {
        if (Math.abs(num) >= 1000000000) {
            return (num / 1000000000).toFixed(1) + "B";
        } else if (Math.abs(num) >= 1000000) {
            return (num / 1000000).toFixed(1) + "M";
        } else if (Math.abs(num) >= 1000) {
            return (num / 1000).toFixed(1) + "K";
        }
    }
    return num.toString();
}
