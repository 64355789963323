// /* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, InputAdornment, Snackbar } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, LoginBanner, Alert, GsFormProvider } from "shared/Components";
import styles from "../styles/Registration.module.css";
import style from "../styles/Login.module.css";
// import { useForm } from "react-hook-form";
import useAutoClose from "shared/Hooks/useAutoClose";
import ReactLoading from "react-loading";
import { useQuery } from "react-query";
import { OldSocialLogin as SocialLogin } from "react-social-login";
import MicrosoftLogin from "react-microsoft-login";
import { Helmet } from "react-helmet";
import { useLogin } from "../hooks/UseLogin";
import { LoginTextField } from "../components/loginField";
import { useGsForm } from "shared/Hooks";
import { RegistrationSchema } from "../schema/AuthenticationSchema";
import { EyeIcon, EyeSlashIcon } from "userlove-icons";

// import styles from "../Login/Login.module.css";

const Registration = () => {
    const navigate = useNavigate();

    const {
        handleRegister,
        handleSocialGoogleRegister,
        handleGoogleRegister,
        isLoading,
        message,
        clearMessage,
        setSocialData,
        socialData,
        handleMicrosoftRegister,

        // methods
    } = useLogin();
    // const {
    // 	register,
    // 	handleSubmit,
    // 	getValues,
    // 	reset,
    // 	watch,
    // 	formState: { errors },
    // } = useForm({
    // 	defaultValues: {
    // 		user_name: "",
    // 		email_id: "",
    // 		password: "",
    // 		company_name: "",
    // 		profile_picture: "",
    // 	},
    // });

    const methods = useGsForm({ resolver: RegistrationSchema });

    const {
        register,
        handleSubmit,
        getValues,
        reset,
        watch,
        formState: { errors },
    } = methods;

    const { data: vendorData, isLoading: vendorsLoading } = useQuery(
        "repoData",
        () =>
            fetch(`${process.env.REACT_APP_LOGIN_SERVER}/user/enable-social-vendors`).then((res) =>
                res.json(),
            ),
        {
            staleTime: Infinity,
        },
    );
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    useAutoClose(message, clearMessage);

    useEffect(() => {
        reset({
            ...getValues(),
            user_name:
                socialData && socialData?.first_name !== null
                    ? socialData.first_name + " " + socialData.last_name
                    : "",
            email_id: socialData ? socialData?.email_id : "",
            password: "",
            company_name: "",
            profile_picture: socialData ? socialData?.profile_picture : "",
        });
    }, [reset, socialData, getValues]);

    const { email_id, user_name, company_name } = watch();
    useEffect(() => {
        sessionStorage.clear();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Helmet>
                <title>Userlove | Sign Up</title>
            </Helmet>
            {vendorsLoading ? (
                <div
                    id="BrowserLoader"
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "fixed",
                        zIndex: 10000000,
                        top: 0,
                        left: 0,
                        backgroundColor: "#fff",
                    }}
                >
                    <img
                        src="/image/BrowserLoaderMain.gif"
                        alt=""
                        style={{
                            width: "120px",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                        }}
                    />
                </div>
            ) : (
                <Grid container className={styles["gs-registration-page"]}>
                    <Grid
                        item
                        xl={6.6}
                        lg={6.6}
                        md={6.6}
                        sm={0}
                        xs={0}
                        // xs={6}
                        height={"100%"}
                        // sx={{ display: "flex ", justifyContent: "flex-end " }}
                        className={styles["gs-registration-banner-rightcontainer"]}
                    >
                        <div className={styles["gs-registration-banner"]}>
                            <LoginBanner />
                        </div>
                    </Grid>
                    <Grid
                        item
                        // xs={6}
                        xl={5.4}
                        lg={5.4}
                        md={5.4}
                        sm={12}
                        xs={12}
                        height={"100%"}
                        sx={{
                            display: "flex ",
                            alignItems: "flex-start",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                        className={styles["gs-registration-banner-leftcontainer"]}
                    >
                        <div className={styles["gs-registration-fields"]}>
                            <div className={styles["gs-registration-topblock"]}>
                                <div className={styles["gs-registration-header"]}>
                                    <img height={"55px"} alt="00" src="/image/loginLogo.png" />
                                </div>
                                <div className={styles["gs-registration-form"]}>
                                    <div className={styles["gs-registration-header-text"]}>
                                        {socialData?.social_login_attempt ? (
                                            <h1>About your company</h1>
                                        ) : (
                                            <h1>Create New Account</h1>
                                        )}
                                        {socialData?.social_login_attempt ? (
                                            <p>
                                                You are almost there!, Please provide your company
                                                name to get started.
                                            </p>
                                        ) : (
                                            <p>
                                                Create a free account with Email, Google or
                                                Microsoft account
                                            </p>
                                        )}
                                    </div>
                                    {!socialData?.social_login_attempt && (
                                        <>
                                            <div className={style["gs-login-social"]}>
                                                {vendorData?.data?.vendors?.map((vendor) => {
                                                    return (
                                                        <>
                                                            {vendor.authentication_server_name ===
                                                                "Google" &&
                                                                vendor.status === 1 && (
                                                                    <SocialLogin
                                                                        provider="google"
                                                                        appId={
                                                                            process.env
                                                                                .REACT_APP_GOOGLE_APP_ID
                                                                        }
                                                                        callback={
                                                                            handleGoogleRegister
                                                                        }
                                                                        redirectUri={
                                                                            process.env
                                                                                .REACT_APP_GOOGLE_REDIRECT
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                style[
                                                                                    "gs-login-social-item"
                                                                                ]
                                                                            }
                                                                        >
                                                                            <img
                                                                                alt="GoogleLogo"
                                                                                height={"18px"}
                                                                                src="/image/google-logo.png"
                                                                                // src={`${process.env.REACT_APP_GRAPHQL_SERVER}${vendor?.logo}`}
                                                                            />
                                                                            <p>
                                                                                Sign up with Google
                                                                            </p>
                                                                        </div>
                                                                    </SocialLogin>
                                                                )}
                                                            {vendor.authentication_server_name ===
                                                                "Microsoft" &&
                                                                vendor.status === 1 && (
                                                                    <MicrosoftLogin
                                                                        prompt="select_account"
                                                                        clientId={
                                                                            process.env
                                                                                .REACT_APP_MICROSOFT_APP_ID
                                                                        }
                                                                        authCallback={(
                                                                            err,
                                                                            data,
                                                                            msal,
                                                                        ) => {
                                                                            handleMicrosoftRegister(
                                                                                err,
                                                                                data,
                                                                                msal,
                                                                            );
                                                                            sessionStorage.clear();
                                                                        }}
                                                                        redirectUri={
                                                                            process.env
                                                                                .REACT_APP_MICROSOFT_REDIRECT
                                                                        }
                                                                        // redirectUri={
                                                                        //   "http://localhost:3000/login/microsoft"
                                                                        // }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                style[
                                                                                    "gs-login-social-item"
                                                                                ]
                                                                            }
                                                                            tabIndex="0"
                                                                        >
                                                                            <img
                                                                                alt="MicrosoftLogo"
                                                                                height={"18px"}
                                                                                // src={`${process.env.REACT_APP_GRAPHQL_SERVER}${vendor?.logo}`}

                                                                                src="/image/Microsoft.svg"
                                                                            />
                                                                            <p>
                                                                                Sign up with
                                                                                Microsoft
                                                                            </p>
                                                                        </div>
                                                                    </MicrosoftLogin>
                                                                )}
                                                        </>
                                                    );
                                                })}
                                            </div>
                                            {vendorData &&
                                                vendorData?.data?.vendors?.length !== 0 && (
                                                    <div className={style["gs-login-or"]}>
                                                        <div
                                                            className={style["gs-login-or-line"]}
                                                        ></div>
                                                        <p>or sign up with email </p>
                                                        <div
                                                            className={style["gs-login-or-line"]}
                                                        ></div>
                                                    </div>
                                                )}
                                        </>
                                    )}

                                    <Snackbar
                                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                        open={!!message.text}
                                    >
                                        <Alert
                                            icon={false}
                                            size="medium"
                                            type={message.type}
                                            sx={{ width: "auto", textAlign: "center" }}
                                        >
                                            {message.text}
                                        </Alert>
                                    </Snackbar>
                                    <GsFormProvider
                                        methods={methods}
                                        onSubmit={handleSubmit(
                                            socialData?.social_login_attempt
                                                ? handleSocialGoogleRegister
                                                : handleRegister,
                                        )}
                                        className={styles["gs-registration-form-fields"]}
                                    >
                                        {(!socialData?.social_login_attempt ||
                                            !socialData?.first_name) && (
                                            <LoginTextField
                                                sx={{ width: "100%" }}
                                                placeholder="John Doe"
                                                label="Name"
                                                {...register("user_name", {
                                                    required: "Name is required",
                                                    minLength: {
                                                        value: 3,
                                                        message: "Too less Characters.",
                                                    },
                                                    maxLength: {
                                                        value: 99,
                                                        message: "Too many Characters.",
                                                    },
                                                    pattern: {
                                                        value: /^[ A-Za-z']*$/,
                                                        message:
                                                            "Numbers and special characters not allowed",
                                                    },
                                                })}
                                                helperText={errors.user_name?.message}
                                                inValid={!!errors.user_name}
                                                fullWidth
                                                value={user_name}
                                                autoFocus="autoFocus"
                                            />
                                        )}
                                        <LoginTextField
                                            sx={{ width: "100%" }}
                                            placeholder="Your Company Name"
                                            label="Company Name   "
                                            {...register("company_name", {
                                                required: "Company Name is required",
                                                pattern: {
                                                    value: /^[ A-Za-z0-9@#$'.]*$/,
                                                    message:
                                                        "Space not allow at first and last & allowed (@,#,$,',.) special characters.",
                                                },
                                                minLength: {
                                                    value: 3,
                                                    message: "Too less Characters.",
                                                },
                                                maxLength: {
                                                    value: 30,
                                                    message: "Too many Characters.",
                                                },
                                            })}
                                            fullWidth
                                            helperText={errors.company_name?.message}
                                            inValid={!!errors.company_name}
                                            value={company_name}
                                        />
                                        {!socialData?.social_login_attempt && (
                                            <LoginTextField
                                                sx={{ width: "100%" }}
                                                placeholder="you@company.com"
                                                label="Email"
                                                disabled={socialData?.social_login_attempt}
                                                fullWidth
                                                {...register("email_id", {
                                                    required: "Email is required",
                                                    // pattern: {
                                                    // 	value: new RegExp(
                                                    // 		/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    // 	),
                                                    // 	message: "Invalid email address",
                                                    // },
                                                })}
                                                value={email_id}
                                                helperText={errors.email_id?.message}
                                                inValid={!!errors.email_id}
                                            />
                                        )}
                                        {!socialData?.social_login_attempt && (
                                            <LoginTextField
                                                sx={{ width: "100%" }}
                                                placeholder="Choose strong password"
                                                label="Password"
                                                fullWidth
                                                type={showPassword ? "text" : "password"}
                                                {...register("password", {
                                                    required: "Password is required",
                                                    pattern: {
                                                        value: new RegExp(
                                                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\s]).+$/,
                                                        ),
                                                        message:
                                                            "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character",
                                                    },
                                                    minLength: {
                                                        value: 8,
                                                        message:
                                                            "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character",
                                                    },
                                                })}
                                                helperText={errors.password?.message}
                                                inValid={!!errors.password}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                // onMouseDown={handleMouseDownPassword}
                                                                edge="end"
                                                                className="gs-password-icon"
                                                            >
                                                                {showPassword ? (
                                                                    <EyeIcon />
                                                                ) : (
                                                                    <EyeSlashIcon />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        )}
                                        <Button
                                            sx={{ marginTop: "10px" }}
                                            className={"gs-primary-button"}
                                            type="submit"
                                            style={{ height: "50px" }}
                                        >
                                            {isLoading ? (
                                                <ReactLoading
                                                    className="gs-enable-disable-loader-bubble"
                                                    type={"bubbles"}
                                                    color={"var(--white-color)"}
                                                />
                                            ) : (
                                                <>
                                                    {socialData?.social_login_attempt
                                                        ? "Finish Sign up"
                                                        : "Sign up with email"}
                                                </>
                                            )}
                                        </Button>
                                        <div
                                            className={styles["gs-registration-form-signup-button"]}
                                        >
                                            <p>Already have an account? </p>
                                            <Button
                                                onClick={() => {
                                                    setSocialData(null);
                                                    navigate("/login");
                                                }}
                                                className={styles["signup-signin-text"]}
                                            >
                                                Sign in
                                            </Button>
                                        </div>
                                    </GsFormProvider>
                                </div>
                            </div>
                            <div className={styles["gs-registration-footer"]}>
                                <p>
                                    By signing up, you agree to our{" "}
                                    {/* <a className={styles["signup-terms-text"]} href="/terms-of-service">Terms of service </a> */}
                                    <Button
                                        className={styles["signup-terms-text"]}
                                        //  onClick={() => {
                                        //   navigate("/terms-of-service")
                                        //  }}
                                    >
                                        Terms of service
                                    </Button>
                                    &nbsp;and acknowledge our{" "}
                                    <Button
                                        className={styles["signup-terms-text"]}
                                        // onClick={() => {
                                        //   navigate("/privacy-policy");
                                        // }
                                        // }
                                    >
                                        Privacy policy
                                    </Button>
                                </p>
                                <div className="gs-privacy-policy">
                                    <p style={{ color: "var(--secondary-light-color)" }}>
                                        © 2023 Userlove LLC.{" "}
                                        <span style={{ textTransform: "capitalize" }}>
                                            All rights reserved.
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Grid>

                    {/* <Grid item xs={6} height={"100%"} className={styles["gs-login-banner-rightcontainer"]}>
						<div className={styles["gs-registration-banner"]}>
							<LoginBanner />
						</div>
					</Grid> */}
                </Grid>
            )}
        </>
    );
};

export default Registration;
